import React from "react";
import Loadable from "react-loadable";
import Loading from "./Loading";

export default {
  home: {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () => import("./Home"),
      loading: Loading,
    }),
  },
  "sales.commissions": {
    path: "/sales-commissions",
    exact: true,
    component: Loadable({
      loader: () => import("./SalesCommission"),
      loading: Loading,
    }),
  },
  "customer.display": {
    path: "/customer-display",
    exact: true,
    component: Loadable({
      loader: () => import("./Sell/CustomerDisplay"),
      loading: Loading,
    }),
  },
  "products.quickscan": {
    path: "/quickscan",
    exact: true,
    component: Loadable({
      loader: () => import("./QuickScan"),
      loading: Loading,
    }),
  },
  "products.barcode-add": {
    path: "/products/add-barcode",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/BarcodeAdd"),
      loading: Loading,
    }),
  },
  "customers.all": {
    path: "/customers",
    exact: true,
    component: Loadable({
      loader: () => import("./Customer/Customers"),
      loading: Loading,
    }),
  },
  "customers.single": {
    path: "/customer/:customerId",
    exact: true,
    component: Loadable({
      loader: () => import("./Customer/Customer"),
      loading: Loading,
    }),
  },
  locations: {
    path: "/locations",
    exact: true,
    component: Loadable({
      loader: () => import("./Locations/All"),
      loading: Loading,
    }),
  },
  "locations.create": {
    path: "/locations/create",
    exact: true,
    component: Loadable({
      loader: () => import("./Locations/Create"),
      loading: Loading,
    }),
  },
  "locations.edit": {
    path: "/locations/:locationId",
    exact: true,
    component: Loadable({
      loader: () => import("./Locations/Create"),
      loading: Loading,
    }),
  },
  "discounts.edit": {
    path: "/discounts/:discountId",
    exact: true,
    component: Loadable({
      loader: () => import("./Discount"),
      loading: Loading,
    }),
  },
  "discounts.list": {
    path: "/discounts",
    exact: true,
    component: Loadable({
      loader: () => import("./Discounts"),
      loading: Loading,
    }),
  },
  "productions.list": {
    path: "/productions",
    exact: true,
    component: Loadable({
      loader: () => import("./Productions"),
      loading: Loading,
    }),
  },
  "productions.create": {
    path: "/productions/create",
    exact: true,
    component: Loadable({
      loader: () => import("./Production"),
      loading: Loading,
    }),
  },
  "productions.edit": {
    path: "/productions/:productionId",
    exact: true,
    component: Loadable({
      loader: () => import("./Production"),
      loading: Loading,
    }),
  },
  "reports.stocks.balance": {
    path: "/stock-balances",
    exact: true,
    component: Loadable({
      loader: () => import("./StockBalance"),
      loading: Loading,
    }),
  },
  "reports.sales.details": {
    path: "/sales-report",
    exact: true,
    component: Loadable({
      loader: () => import("./SalesDetails"),
      loading: Loading,
    }),
  },
  "reports.sales.summary": {
    path: "/sales-report",
    exact: true,
    component: Loadable({
      loader: () => import("./SalesSummary"),
      loading: Loading,
    }),
  },
  "reports.suppliers": {
    path: "/suppliers-report",
    exact: true,
    component: Loadable({
      loader: () => import("./SupplierReport"),
      loading: Loading,
    }),
  },
  "products.stock.movement": {
    path: "/stock-movements",
    exact: true,
    component: Loadable({
      loader: () => import("./StockMovements"),
      loading: Loading,
    }),
  },
  "products.stock.take": {
    path: "/stock-take",
    exact: true,
    component: Loadable({
      loader: () => import("./StockTake"),
      loading: Loading,
    }),
  },
  employees: {
    path: "/employees",
    exact: true,
    component: Loadable({
      loader: () => import("./Employees/All"),
      loading: Loading,
    }),
  },
  "employees.create": {
    path: "/employees/create",
    exact: true,
    component: Loadable({
      loader: () => import("./Employees/Create"),
      loading: Loading,
    }),
  },
  "employees.edit": {
    path: "/employees/:employeeId",
    exact: true,
    component: Loadable({
      loader: () => import("./Employees/Create"),
      loading: Loading,
    }),
  },
  "products.barcode": {
    path: "/products/barcodes",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/BarcodeGenerate"),
      loading: Loading,
    }),
  },
  sell: {
    path: "/sell",
    exact: true,
    component: Loadable({
      loader: () => import("./Sell/index"),
      loading: Loading,
    }),
  },
  "sell-vouchers": {
    path: "/sell/vouchers",
    exact: true,
    component: Loadable({
      loader: () => import("./Sell/Vouchers"),
      loading: Loading,
    }),
  },
  "products.inventory": {
    path: "/products/inventory",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Inventory"),
      loading: Loading,
    }),
  },
  "products.all": {
    path: "/products",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/All"),
      loading: Loading,
    }),
  },
  "products.add": {
    path: "/products/add",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Add"),
      loading: Loading,
    }),
  },
  "products.import": {
    path: "/products/import",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/ImportProduct"),
      loading: Loading,
    }),
  },
  "products.aedit": {
    path: "/products/:productId/edit",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Add"),
      loading: Loading,
    }),
  },
  "products.history.edit": {
    path: "/products/history/:historyId",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/History"),
      loading: Loading,
    }),
  },
  "products.category.all": {
    path: "/products/categories",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Categories"),
      loading: Loading,
    }),
  },
  "products.category.create": {
    path: "/products/categories/add",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Category"),
      loading: Loading,
    }),
  },
  "products.category.single": {
    path: "/products/categories/:categoryId",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Category"),
      loading: Loading,
    }),
  },
  "products.history.transfer": {
    path: "/products/transfers",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/StockTransfer"),
      loading: Loading,
    }),
  },
  "products.transfer.direct": {
    path: "/products/direct-transfer",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/DirectTransfer"),
      loading: Loading,
    }),
  },
  "products.history.transfer.edit": {
    path: "/products/transfers/:transferId",
    exact: false,
    component: Loadable({
      loader: () => import("./Products/StockTransfer"),
      loading: Loading,
    }),
  },
  "products.suppliers": {
    path: "/products/suppliers",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Suppliers"),
      loading: Loading,
    }),
  },
  "products.supplier.create": {
    path: "/products/suppliers/create",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Suppliers/Supplier"),
      loading: Loading,
    }),
  },
  "products.supplier.edit": {
    path: "/products/suppliers/:supplierId",
    exact: true,
    component: Loadable({
      loader: () => import("./Products/Suppliers/Supplier"),
      loading: Loading,
    }),
  },
};
