import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDYIlO4ycbCGMMWKmqeqrLgBlXPjTU6Rf4",
    authDomain: "ahoom-a50d6.firebaseapp.com",
    databaseURL: "https://ahoom-a50d6.firebaseio.com",
    projectId: "ahoom-a50d6",
    storageBucket: "ahoom-a50d6.appspot.com",
    messagingSenderId: "818496379405",
    appId: "1:818496379405:web:60acf154f52c5f2e"
};


firebase.initializeApp(firebaseConfig);

export default firebase;
