import React from 'react';
import {Input, Button} from "antd";
import firebase from '../services/firebase';
import * as pkg from '../../package.json';
import Logo from '../images/logo.png';

class Login extends React.Component{

	state = {
		loaded: false,
		loading: false,
		email: '',
		password: ''
	}

	signIn = async () => {

		const {email, password} = this.state;

		this.setState({loading: true});

		try {

			await firebase.auth().signInWithEmailAndPassword(
				email,
				password
			);

			this.setState({loading: false});

		} catch(e) {

			this.setState({
                loading: false,
                error: e.message
            });

		}

	}


	render() {

		const {email, password, loading, error} = this.state;

		return (
			<div className="w-full h-full flex items-center justify-center">

                <form onSubmit={(e) => {

					e.preventDefault();

					e.stopPropagation();

					this.signIn();

				}} className="max-w-xs w-full shadow-lg rounded-lg pt-6 px-6">
                    <img className="h-16 block mx-auto" src={Logo} title="Ahoom Logo" />
					<div className="mt-10">
						<div className="my-3">
							<Input onChange={(e) => this.setState({email: e.target.value})} placeholder="Email" />
						</div>
						<div className="my-3">
							<Input.Password onChange={(e) => this.setState({password: e.target.value})} placeholder="Password" />
						</div>
						{(error) ? <p className="text-red-700 text-xs mb-4">{error}</p> : null}
						<div className="my-6">
							<Button htmlType="submit" loading={loading} type="primary" size="large" block ghost>Login</Button>
						</div>
					</div>
					<p className="text-center py-3">v{pkg.version}</p>
                </form>

            </div>
		);

	}

}

export default Login;
