import React from 'react';
import {Spin} from "antd";
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import CreateAdmin from './components/CreateAdmin';
import CreateVendor from './components/CreateVendor';
import Loading from './components/Loading';

import { ApolloProvider} from "react-apollo";
import firebase from './services/firebase';
import apollo from './services/apollo';

import gql from "graphql-tag";
import { Query } from "react-apollo";

const SUB = gql`
	query ($firebase_id: String!) {
		employees(where: {firebase_id: {_eq: $firebase_id}}) {
			id,
			full_name,
			role,
			firebase_id
			vendor {
				id
				name
			}
			location {
				id
				name
				is_ahoomstore
				salesperson_percent
				supervisor_percent
				commission_percent
			}
			vendors {
				id
				name
			}
		}
	}
`;

class App extends React.Component{

	state = {
		loaded: false,
		user: null
	}

	componentDidMount() {

		// setTimeout(() => this.setState({loaded: true}), 1000);

		firebase.auth().onAuthStateChanged((user) => {

			this.setState({user, loaded: true});


		});

	}

	render() {

		const {loaded, user} = this.state;

		return (
			(loaded) ? 
			<ApolloProvider client={apollo}>
				{user ? <Query
					query={SUB}
					variables={{
						firebase_id: user.uid
					}}
				>
					
					{({ data, loading, error }) => {

						// if (data) console.log(data.employees[0]);

						return !loading && data ? !data.employees.length ? <CreateAdmin user={user} /> : (!data.employees[0].vendors.length && !data.employees[0].vendor) ? <CreateVendor admin_id={data.employees[0].id} /> :<Dashboard
							employee={data.employees[0]}
							vendor={data.employees[0].vendors.length ?data.employees[0].vendors[0]: data.employees[0].vendor}
						/>: <Loading />

					}}
				</Query>: <Login />}
			</ApolloProvider>: <Loading />
		);

	}

}

export default App;
