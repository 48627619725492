import React, { Fragment } from "react";
import { Menu, Tag } from "antd";
import {
  Users,
  Box,
  Home,
  MapPin,
  Clipboard,
  Settings as SettingsIcon,
  Briefcase,
  DollarSign,
  ShoppingBag,
  Activity,
  Package,
  Truck,
} from "react-feather";
import { find, values, toPairs, includes } from "lodash";
import routes from "./routes";
import Logo from "../images/logo.png";
import MiniLogo from "../images/mini-logo.png";

import { withRouter } from "react-router-dom";

const { SubMenu } = Menu;

class SideMenu extends React.Component {
  state = {};

  handleClick = (e) => {
    const { history } = this.props;
    const route = routes[e.key];

    if (route) {
      history.push(route.path);
    }
  };

  componentDidMount() {
    this.setState(
      {
        routes: toPairs(routes).map((r) => {
          return {
            key: r[0],
            ...r[1],
          };
        }),
      },
      () => {
        const { location } = this.props.history;

        this.findAndSetCurrent(location.pathname);
      }
    );

    this.props.history.listen((location, action) => {
      this.findAndSetCurrent(location.pathname);
      
    });
  }

  findAndSetCurrent = (path) => {
    const route = find(this.state.routes, {
      path,
    });

    if (route) {
      this.setState({
        currentRoute: route.key,
      });
    }
  };

  render() {
    const { currentRoute } = this.state;
    const { employee, sideMenuOpen } = this.props;
    const { role } = employee;

    const isSupervisor = role === "supervisor";
    const isSourcing = role === "sourcing";

    const isDisplay = currentRoute === "customer.display";

    // console.log(currentRoute);

    return (
      <Fragment>
        <div style={{ width: isDisplay ? 0: sideMenuOpen ? 256 : 90 }}></div>

        <div className="fixed pin-l h-screen overflow-auto z-index">
          {isDisplay ? null : (
            <Menu
              className="h-full sidemenu"
              onClick={this.handleClick}
              style={{ width: isDisplay ? 0: sideMenuOpen ? 256 : 90 }}
              selectedKeys={[currentRoute]}
              inlineCollapsed={true}
              mode="inline"
              // co
            >
              <img
                src={sideMenuOpen ? Logo : MiniLogo}
                className="h-16 block mx-auto mt-4 mb-8"
                alt=""
              />

              <Menu.Item key="home">
                <span className="flex items-center">
                  <Home size={18} className="mr-2" />
                  {sideMenuOpen ? <span>Home</span> : null}
                </span>
              </Menu.Item>
              {!isSupervisor && includes(["admin"], role) ? (
                <SubMenu
                  key="locations"
                  title={
                    <span className="flex items-center">
                      <MapPin size={18} className="mr-2" />
                      {sideMenuOpen ? <span>Locations</span> : null}
                    </span>
                  }
                >
                  <Menu.Item key="locations">All Locations</Menu.Item>
                  <Menu.Item key="locations.create">Add new</Menu.Item>
                </SubMenu>
              ) : null}
              {isSupervisor || includes(["admin", "audit"], role) ? (
                <SubMenu
                  // key="sell"
                  title={
                    <span className="flex items-center">
                      <ShoppingBag size={18} className="mr-2" />
                      {sideMenuOpen ? <span>Sell</span> : null}
                    </span>
                  }
                >
                  <Menu.Item key="sell">Products</Menu.Item>
                  <Menu.Item key="sell-vouchers">Vouchers</Menu.Item>
                </SubMenu>
              ) : null}
              {includes(
                ["admin", "supervisor", "warehouse", "audit", "sourcing"],
                role
              ) ? (
                <SubMenu
                  key="products"
                  title={
                    <span className="flex items-center">
                      <Box size={18} className="mr-2" />
                      {sideMenuOpen ? <span>Products</span> : null}
                    </span>
                  }
                >
                  {includes(["admin", "audit", "sourcing"], role) ? (
                    <Menu.Item key="products.all">All Products</Menu.Item>
                  ) : null}
                  {includes(["admin", "audit", "sourcing"], role) ? (
                    <Menu.Item key="products.quickscan">Quick Scan</Menu.Item>
                  ) : null}
                  {/* {includes(['admin', 'audit', 'sourcing'], role) ? <Menu.Item key="products.import">Import Products</Menu.Item>: null} */}
                  {includes(["admin", "audit", "sourcing"], role) ? (
                    <Menu.Item key="products.add">Add new</Menu.Item>
                  ) : null}
                  {includes(
                    ["admin", "warehouse", "audit", "sourcing"],
                    role
                  ) ? (
                    <Menu.Item key="products.barcode">New Inventory</Menu.Item>
                  ) : null}
                  {includes(
                    ["admin", "warehouse", "audit", "sourcing"],
                    role
                  ) ? (
                    <Menu.Item key="products.barcode-add">
                      New Inventory (Barcode) <Tag color="blue">new</Tag>
                    </Menu.Item>
                  ) : null}
                  {includes(["admin", "audit", "sourcing"], role) ? (
                    <Menu.Item key="products.category.all">
                      Categories
                    </Menu.Item>
                  ) : null}
                  {includes(["admin", "audit", "sourcing"], role) ? (
                    <Menu.Item key="products.suppliers">Suppliers</Menu.Item>
                  ) : null}
                  {/* {includes(['admin', "warehouse", 'audit'], role) ? <Menu.Item key="products.inventory">Inventory</Menu.Item>: null} */}
                  {includes(
                    ["admin", "warehouse", "audit", "supervisor", "sourcing"],
                    role
                  ) ? (
                    <Menu.Item key="products.history.transfer">
                      Stock Transfer
                    </Menu.Item>
                  ) : null}
                  {includes(["admin"], role) ? (
                    <Menu.Item key="products.transfer.direct">
                      Direct Transfer
                    </Menu.Item>
                  ) : null}
                  {includes(["admin", "audit"], role) ? (
                    <Menu.Item key="products.stock.take">Stock Take</Menu.Item>
                  ) : null}
                  {/* <Menu.Item key="prodicts.receivings">Receivings</Menu.Item> */}
                  {/* <Menu.Item key="4">Transfer Order</Menu.Item> */}
                </SubMenu>
              ) : null}
              {includes(["admin", "production", "audit", "sourcing"], role) ? (
                <Menu.Item key="productions.list">
                  <span className="flex items-center">
                    <Briefcase size={18} className="mr-2" />
                    {sideMenuOpen ? (
                      <span>{isSourcing ? "Sourcing" : "Production"}</span>
                    ) : null}
                  </span>
                </Menu.Item>
              ) : null}
              {!isSupervisor &&
              includes(["admin", "audit", "manager"], role) ? (
                <Menu.Item key="discounts.list">
                  <span className="flex items-center">
                    <DollarSign size={18} className="mr-2" />
                    {sideMenuOpen ? <span>Discounts</span> : null}
                  </span>
                </Menu.Item>
              ) : null}
              <SubMenu
                key="reports"
                title={
                  <span className="flex items-center">
                    <Activity size={18} className="mr-2" />
                    {sideMenuOpen ? <span>Reports</span> : null}
                  </span>
                }
              >
                {/* {includes(
              [
                "admin",
                "manager",
                "audit",
                "warehouse",
                "supervisor",
                "sourcing",
              ],
              role
            ) ? (
              <Menu.Item key="reports.sales.summary">Sales Summary</Menu.Item>
            ) : null} */}
                {includes(
                  [
                    "admin",
                    "manager",
                    "audit",
                    "warehouse",
                    "supervisor",
                    "sourcing",
                  ],
                  role
                ) ? (
                  <Menu.Item key="reports.sales.details">
                    Sales Report Details
                  </Menu.Item>
                ) : null}
                {includes(
                  [
                    "admin",
                    "manager",
                    "audit",
                    "warehouse",
                    "supervisor",
                    "sourcing",
                  ],
                  role
                ) ? (
                  <Menu.Item key="reports.stocks.balance">
                    Stock Balance
                  </Menu.Item>
                ) : null}
                {includes(
                  [
                    "admin",
                    "manager",
                    "audit",
                    "warehouse",
                    "supervisor",
                    "sourcing",
                  ],
                  role
                ) ? (
                  <Menu.Item key="products.stock.movement">
                    Stock Movement
                  </Menu.Item>
                ) : null}
                {includes(["admin", "manager", "audit", "sourcing"], role) ? (
                  <Menu.Item key="reports.suppliers">
                    Suppliers Report
                  </Menu.Item>
                ) : null}
                {includes(["admin", "manager", "audit"], role) ? (
                  <Menu.Item key="sales.commissions">Commission</Menu.Item>
                ) : null}
              </SubMenu>
              {includes(["admin", "audit", "manager"], role) ? (
                <SubMenu
                  key="employees"
                  title={
                    <span className="flex items-center">
                      <Clipboard size={18} className="mr-2" />
                      {sideMenuOpen ? <span>Employees</span> : null}
                    </span>
                  }
                >
                  <Menu.Item key="employees">All Employees</Menu.Item>
                  {includes(["admin"], role) ? (
                    <Menu.Item key="employees.create">Add new</Menu.Item>
                  ) : null}
                  {/* <Menu.Item key="employees">Roles</Menu.Item> */}
                </SubMenu>
              ) : null}

              {!includes(
                ["salesperson", "warehouse", "production", "audit"],
                role
              ) ? (
                <SubMenu
                  key="customers"
                  title={
                    <span className="flex items-center">
                      <Users size={18} className="mr-2" />
                      {sideMenuOpen ? <span>Customers</span> : null}
                    </span>
                  }
                >
                  <Menu.Item key="customers.all">All Customers</Menu.Item>
                  <Menu.Item key="customers.single">Add new</Menu.Item>
                </SubMenu>
              ) : null}
              {/* <Menu.Item key="settings">
                        <span className="flex items-center">
                            <SettingsIcon size={18} className="mr-2" />
                            <span>Settings</span>
                        </span>
                    </Menu.Item> */}
            </Menu>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(SideMenu);
