import React from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { includes } from "lodash";
// import * as pkg from '../../package.json';
import routes from "./routes";
import permissions from "../services/permissions";

class Dashboard extends React.Component {
  state = {
    loaded: false,
    sideMenuOpen: true,
  };

  toggleDrawer = () =>
    this.setState({ sideMenuOpen: !this.state.sideMenuOpen });

  render() {
    const { employee, vendor } = this.props;
    const { sideMenuOpen } = this.state;

    // console.log(sideMenuOpen);

    return (
      <div className="w-full h-screen flex">
        <Router>
          <div className="print-hidden">
            <SideMenu
              sideMenuOpen={sideMenuOpen}
              toggleDrawer={this.toggleDrawer}
              employee={employee}
              vendor={vendor}
            />
          </div>
          <Switch>
            <div className="flex-1 flex flex-col">
              <div className="print-hidden">
                <Header
                  employee={employee}
                  vendor={vendor}
                  sideMenuOpen={sideMenuOpen}
                  toggleDrawer={this.toggleDrawer}
                />
                <div className="h-16"></div>
              </div>
              {/* <Redirect from="/" to={homePages[employee.role]} /> */}
              <div className="flex-1">
                {Object.keys(routes).map((key) => {
                  const { component: Component, path, exact } = routes[key];

                  return (
                    <Route
                      key={key}
                      path={path}
                      render={(props) => {
                        return includes(permissions[path], employee.role) ? (
                          <Component
                            {...props}
                            employee={employee}
                            vendor={vendor}
                          />
                        ) : (
                          <div className="w-full h-full flex flex-col items-center justify-center">
                            <h1 className="text-xl font-bold text-black">
                              Permission Error
                            </h1>
                            <p className="text-black my-3">
                              You don't have the appropriate permission to view
                              this page, kindly contact the systems
                              administrator.
                            </p>
                          </div>
                        );
                      }}
                      exact={exact}
                    />
                  );
                })}
              </div>
            </div>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Dashboard;
