import React from "react";
import { Menu, Dropdown, Select, Button, Badge, Popover } from "antd";
import { Bell } from "react-feather";
import { Link, withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { Subscription } from "react-apollo";
import moment from "moment";
import ChangePassword from "./ChangePassword";

import firebase from "../services/firebase";

const LOCATIONS_SUB = gql`
  subscription {
    locations(order_by: { created_at: desc }) {
      currency
      name
      vendor_id
      id
      vat
      created_by
      created_at
      approved_by
      address
    }
  }
`;

class Header extends React.Component {
  state = {
    online: false,
    showChangePassword: false,
  };

  menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => this.setState({ showChangePassword: true })}
      >
        Change Password
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="3"
        onClick={() => {
          this.userRef.set("offline");
          this.lastseen.set(Date.now());

          firebase.auth().signOut();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  get GET_PENDING() {
    const { employee } = this.props;

    return gql`
            subscription{
                product_history(where: {
                    _and: [
                        {type: {_eq: "TRANSFER_STOCK"}},
                        ${
                          employee.location
                            ? `{_or: [
                            {from_location: {_eq: ${employee.location.id}}},
                            {to_location: {_eq: ${employee.location.id}}},
                        ]},`
                            : ""
                        }
                        ${
                          employee.location
                            ? `{_and: [
                            {pending: {_eq: false}},
                            {_or:[
                                {received: {_eq: false}},
                                {received: {_is_null: true}}
                            ]}
                        ]}`
                            : `{_and: [
                            {pending: {_eq: true}},
                            {_or:[
                                {received: {_eq: false}},
                                {received: {_is_null: true}}
                            ]}
                        ]}`
                        }
                    ]
                }, order_by: {created_at: desc}) {
                    id
                    type
                    to_location
                    from_location
                    created_at
                    employee {
                        full_name
                    }
                    products_inventory_histories_aggregate {
                        aggregate {
                            sum {
                                qty
                            }
                        }
                    }
                    _to_location {
                        name
                    }
                }
            }
        
        `;
  }

  componentDidMount() {
    const { employee } = this.props;
    const db = firebase.database();

    var isOnline = db.ref("/.info/connected");
    var sessions = db.ref("/sessions/" + employee.id);
    this.lastseen = db.ref("/online/" + employee.id + "/lastseen");
    this.userRef = db.ref("/online/" + employee.id + "/status");

    isOnline.on("value", (snapshot) => {
      if (snapshot.val()) {
        this.userRef.onDisconnect().set("offline");
        this.userRef.set("online");

        this.lastseen
          .onDisconnect()
          .set(firebase.database.ServerValue.TIMESTAMP);

        var sessionRef = sessions.push();
        sessionRef
          .child("ended")
          .onDisconnect()
          .set(firebase.database.ServerValue.TIMESTAMP);
        sessionRef.child("began").set(firebase.database.ServerValue.TIMESTAMP);

        this.setState({ online: true });
      } else {
        this.setState({ online: false });
      }
    });
  }

  render() {
    const { showChangePassword, online } = this.state;
    const { employee, sideMenuOpen, toggleDrawer } = this.props;
    const hidden = this.props.history.location.pathname === "/customer-display";
    const width =  hidden ? 0 :sideMenuOpen ? 256 : 90;

    return (
      <div
        style={{
          marginLeft: hidden ? 0 : sideMenuOpen ? 256 : 90,
        }}
        className="h-16 fixed top-0 right-0 left-0 bg-white z-30 border-b px-4"
      >
        {!hidden ? (
          <div className="flex items-center justify-between h-full">
            <div className="flex">
              <div className="flex items-center mr-4">
                <Button icon="menu" onClick={toggleDrawer} />
              </div>
              {online ? (
                <div className="flex items-center" onClick={toggleDrawer}>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                  <p className="text-green-500 uppercase text-medium pl-3">
                    Online
                  </p>
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-red-500"></div>
                  <p className="text-red-500 uppercase text-medium pl-3">
                    Offline
                  </p>
                </div>
              )}
              {employee.location ? (
                <p className="font-bold text-black text-lg">
                  {employee.location.name}
                </p>
              ) : null}
            </div>
            <div className=""></div>
            <div className="flex items-center">
              <div className="mr-6">
                <Subscription subscription={this.GET_PENDING}>
                  {({ data, loading, error }) => {
                    return (
                      <Popover
                        placement="bottom"
                        content={
                          !loading && data && data.product_history.length ? (
                            <div
                              className="overflow-scroll"
                              style={{
                                width,
                                maxHeight: 500,
                              }}
                            >
                              {data.product_history.map(
                                (
                                  {
                                    created_at,
                                    products_inventory_histories_aggregate,
                                    id,
                                    _to_location,
                                    employee,
                                  },
                                  index
                                ) => {
                                  // console.log(products_inventory_histories_aggregate);

                                  return (
                                    <React.Fragment key={id}>
                                      <p
                                        className={[
                                          "py-2",
                                          index == 0 ? "" : "border-t",
                                        ].join(" ")}
                                      >
                                        <Link to={`/products/transfers/${id}`}>
                                          <span className="font-bold">
                                            {employee.full_name}
                                          </span>{" "}
                                          has requested a transfer of{" "}
                                          {
                                            products_inventory_histories_aggregate
                                              .aggregate.sum.qty
                                          }{" "}
                                          items to{" "}
                                          <span className="font-bold">
                                            {_to_location.name}
                                          </span>
                                        </Link>
                                      </p>
                                      <p>{moment(created_at).fromNow()}</p>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <p className="p-4">No pending transfers</p>
                          )
                        }
                      >
                        <Badge
                          count={
                            !loading && data ? data.product_history.length : 0
                          }
                        >
                          <Button
                            loading={loading}
                            disabled={loading}
                            shape="circle"
                            type="primary"
                            ghost
                          >
                            <div className="flex items-center justify-center">
                              <Bell size={16} />
                            </div>
                          </Button>
                        </Badge>
                      </Popover>
                    );
                  }}
                </Subscription>
              </div>
              <Dropdown overlay={this.menu}>
                <button className="focus:outline-none flex items-center">
                  <div className="">{employee.full_name}</div>
                  <div className="w-10 h-10 border rounded-full bg-grey ml-2"></div>
                </button>
              </Dropdown>
            </div>
          </div>
        ) : null}
        <ChangePassword
          employee={employee}
          visible={showChangePassword}
          onClose={() => this.setState({ showChangePassword: false })}
        />
      </div>
    );
  }
}

export default withRouter(Header);
