import React from 'react';
import {Input, Button} from "antd";


import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const ADD_MUTATION = gql`
    mutation Add($name: String!, $admin_id: Int!) {
        insert_vendors(objects: {
            name: $name,
            admin_id: $admin_id
        }) {
            returning {
              id
            }
        }
    }
`

class CreateVendor extends React.Component {

    state = {
        name: ''
    }

    render() {

        const {name} = this.state;
        const {admin_id} = this.props;

        return (
            <div className="flex flex-col items-center my-32 h-full w-full">
                <div className="max-w-sm w-full shadow-lg rounded-lg p-6">
                    <p className="font-bold text-lg">Create Vendor</p>
                    <div className="my-4">
                        <p className="font-bold">Vendor Name</p>
                        <Input placeholder="Vendor Name" onChange={(e) => this.setState({name: e.target.value})} />
                    </div>
                    <div className="mt-6">
                        <Mutation mutation={ADD_MUTATION} variables={{
                            name,
                            admin_id,
                        }}>
                            {(add, {loading}) => <Button loading={loading} type="primary" ghost onClick={add}>Create</Button>}
                        </Mutation>
                    </div>
                </div>
            </div>
        );

    }

}

export default CreateVendor;
