import React from 'react';
import {Input, Button, Radio, DatePicker} from "antd";
import moment from 'moment';

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
const dateFormatList = 'DD/MM/YYYY';

const ADD_MUTATION = gql`
    mutation Add($full_name: String!, $firebase_id: String!, $email: String!, $date_joined: timestamp!, $date_of_birth: date!, $gender: String!) {
        insert_employees(objects: {
            allow_login: true,
            date_joined: $date_joined,
            date_of_birth: $date_of_birth,
            email: $email,
            firebase_id: $firebase_id,
            full_name: $full_name,
            gender: $gender,
            role: "admin"
        }) {
            returning {
              id
            }
        }
    }
`

class CreateAdmin extends React.Component {

    state = {
        full_name: '',
        email: this.props.user.email,
        gender: 'Male',
        date_joined: moment().format(dateFormatList),
        date_of_birth: moment().format(dateFormatList)
    }

    render() {

        const {full_name, email, gender, date_joined, date_of_birth} = this.state;
        const {user} = this.props;

        return (
            <div className="flex flex-col items-center my-32 h-full w-full">
                <div className="max-w-sm w-full shadow-lg rounded-lg p-6">
                    <p className="font-bold text-lg">Create Admin</p>
                    <div className="my-4">
                        <p className="font-bold">Full name</p>
                        <Input placeholder="Fullname" onChange={(e) => this.setState({full_name: e.target.value})} />
                    </div>
                    <div className="my-4">
                        <p className="font-bold">Email address</p>
                        <Input placeholder="Email" disabled={true} defaultValue={email} />
                    </div>
                    <div className="my-4">
                        <p className="font-bold">Gender</p>
                        <Radio.Group defaultValue={gender} onChange={(e) => this.setState({gender: e.target.value})} >
                            <Radio value="Male">Male</Radio>
                            <Radio value="Female">Female</Radio>
                        </Radio.Group>
                    </div>
                    <div className="flex justify-between my-4 -mx-2">
                        <div className="px-2">
                            <p className="font-bold">Date of Birth</p>
                            <DatePicker defaultValue={moment(date_of_birth, dateFormatList)} onChange={(date) => this.setState({date_of_birth: date.format(dateFormatList)})} format={dateFormatList} />
                        </div>
                        <div className="px-2">
                            <p className="font-bold">Date Joined</p>                        
                            <DatePicker defaultValue={moment(date_joined, dateFormatList)} onChange={(date) => this.setState({date_joined: date.format(dateFormatList)})} format={dateFormatList} />
                        </div>
                    </div>
                    <div className="mt-6">
                        <Mutation mutation={ADD_MUTATION} variables={{
                            full_name,
                            email,
                            gender,
                            date_joined: moment(date_joined, dateFormatList).toDate(),
                            date_of_birth: moment(date_of_birth, dateFormatList).format('YYYY-MM-DD'),
                            firebase_id: user.uid
                        }}>
                            {(add, {loading}) => <Button loading={loading} type="primary" ghost onClick={add}>Create</Button>}
                        </Mutation>
                    </div>
                </div>
            </div>
        );


    }

}

export default CreateAdmin;