import React from "react";
import { Input, Modal, message } from "antd";
import axios from "axios";

class ChangePassword extends React.Component {
  state = {
    password: "",
    repeat_password: "",
    visible: this.props.visible,
    employee: this.props.employee,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.setState({
        visible: this.props.visible,
      });
    }
  }

  onClose = () => this.setState({ visible: false }, this.props.onClose);

  save = async () => {
    const { password, repeat_password, employee } = this.state;

    if (password.length < 8)
      return message.error("Password must be at 8 characters long.");
    if (password !== repeat_password)
      return message.error("Passwords do not match");

    this.setState({ loading: true });

    const res = await axios.post(
      `https://us-central1-ahoom-a50d6.cloudfunctions.net/api/users/${employee.firebase_id}`,
      {
        password,
      },
      {
        timeout: 10000,
      }
    );

    this.setState({ loading: false }, this.onClose);
  };

  render() {
    const { visible, employee, password, repeat_password, loading } =
      this.state;

    return (
      <Modal
        title="Change Password"
        onCancel={this.onClose}
        okText="Change"
        visible={visible}
        confirmLoading={loading}
        onOk={this.save}
      >
        <div className="mb-4">
          <p className="font-bold text-black">Employee Name</p>
          <p className="font-medium text-lg text-black">{employee.full_name}</p>
        </div>
        <div className="my-2">
          <p className="font-bold text-black">
            Password must be at least 8 characters long
          </p>
        </div>
        <div className="my-2">
          <Input.Password
            defaultValue={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="Enter password"
          />
        </div>
        <div className="my-2">
          <Input.Password
            defaultValue={repeat_password}
            onChange={(e) => this.setState({ repeat_password: e.target.value })}
            placeholder="Repeat password"
          />
        </div>
      </Modal>
    );
  }
}

export default ChangePassword;
