import React from 'react';
import {Spin, Button} from "antd";


const ConnectionError = (props) => (
    <div className="w-full h-full flex flex-col items-center justify-center">
        <h1 className="text-xl font-bold text-black">Connection Error</h1>
        <p className="text-black my-3">
            No Internet
        </p>
        <Button
            type="primary"
            onClick={props.retry}
            ghost
            size="large"
        >
            Retry
        </Button>
    </div>
);

const TimeoutError = (props) => (
    <div className="w-full h-full flex flex-col items-center justify-center">
        <h1 className="text-xl font-bold text-black">Timeout Error</h1>
        <p className="text-black my-3">
            Request took too long to respond.
        </p>
        <Button
            type="primary"
            onClick={props.retry}
            ghost
            size="large"
        >
            Retry
        </Button>
    </div>
);

class Loading extends React.Component{

    render() {

        const {props} = this;

        if (props.error) console.log(props.error);

        return (
            props.error  ? <ConnectionError {...props} /> : props.pastDelay ? props.timedOut ? <TimeoutError {...props} /> : <div className="w-full h-full flex items-center justify-center">
                <Spin size="large"/>
            </div> : null
        )

    }

};

export default Loading;