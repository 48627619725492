export default {
  "/": ["admin", "manager", "audit", "sourcing", "warehouse", "supervisor"],
  "/locations": ["admin"],
  "/locations/create": ["admin"],
  "/locations/:locationId": ["admin"],
  "/discounts/create": ["admin", "audit", "manager"],
  "/discounts/:discountId": ["admin", "audit", "manager"],
  "/discounts": ["admin", "audit", "manager"],
  "/quickscan": ["admin", "production", "audit", "sourcing"],
  "/productions": ["admin", "production", "audit", "sourcing"],
  "/productions/create": ["admin", "production", "audit", "sourcing"],
  "/productions/:productionId": ["admin", "production", "audit", "sourcing"],
  "/stock-balances": [
    "admin",
    "manager",
    "audit",
    "sourcing",
    "warehouse",
    "supervisor",
  ],
  "/sales-report": [
    "admin",
    "manager",
    "audit",
    "sourcing",
    "warehouse",
    "supervisor",
  ],
  "/sales-commissions": ["admin", "manager", "audit"],
  "/stock-movements": [
    "admin",
    "manager",
    "audit",
    "sourcing",
    "warehouse",
    "supervisor",
  ],
  "/suppliers-report": ["admin", "manager", "audit", "sourcing"],
  "/stock-take": ["admin", "audit"],
  "/employees": ["admin", "audit", "manager"],
  "/employees/create": ["admin"],
  "/employees/:employeeId": ["admin"],
  "/products/barcodes": ["admin", "warehouse", "audit", "sourcing"],
  "/products/add-barcode": ["admin", "warehouse", "audit", "sourcing"],
  "/sell/vouchers": ["supervisor", "admin", "audit"],
  "/sell": ["supervisor", "admin", "audit"],
  "/customer-display": ["supervisor", "admin", "audit"],
  "/customers": ["supervisor", "admin", "audit"],
  "/customer/:customerId": ["supervisor", "admin", "audit"],
  "/products/inventory": [],
  "/products": ["admin", "audit", "sourcing"],
  "/products/import": ["admin", "audit"],
  "/products/add": ["admin", "audit", "sourcing"],
  "/products/:productId/edit": ["admin", "audit", "sourcing"],
  "/products/history/:historyId": [
    "admin",
    "manager",
    "audit",
    "warehouse",
    "supervisor",
    "sourcing",
  ],
  "/products/categories": ["admin", "audit", "sourcing"],
  "/products/categories/add": ["admin", "audit", "sourcing"],
  "/products/categories/:categoryId": ["admin", "audit", "sourcing"],
  "/products/direct-transfer": ["admin"],
  "/products/transfers": [
    "admin",
    "warehouse",
    "audit",
    "supervisor",
    "sourcing",
  ],
  "/products/transfers/:transferId": [
    "admin",
    "manager",
    "audit",
    "warehouse",
    "supervisor",
    "sourcing",
  ],
  "/products/suppliers": ["admin", "audit", "sourcing"],
  "/products/suppliers/create": ["admin", "audit", "sourcing"],
  "/products/suppliers/:supplierId": ["admin", "audit", "sourcing"],
};
