import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { setContext } from "apollo-link-context";
import firebase from "./firebase";

const headers = {};

const httpLink = new HttpLink({
  uri:
    // process.env.NODE_ENV === "production"
    // 	?
    "https://graphql.nallemclothing.com/v1/graphql",
  // : "http://127.0.0.1:8085/v1/graphql"
});

const WSS =
  // process.env.NODE_ENV === "production"
  // ?
  "wss://graphql.nallemclothing.com/v1/graphql";
// : "ws://127.0.0.1:8085/v1/graphql";

const _client = new SubscriptionClient(WSS, {
  reconnect: true,
  timeout: 10000,
  lazy: true,
  connectionParams: async () => {
    const token = await firebase.auth().currentUser.getIdToken();

    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await firebase.auth().currentUser.getIdToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsLink = new WebSocketLink(_client);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      context: { timeout: 10000 },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default client;
